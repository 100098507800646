import React, { useContext, useRef } from "react";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { useEffect } from "react";
import { useState } from "react";
import { newAttributeSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../../md/full";
import { useAppContext } from "../../../contexts/AppContext";
import UpDownButton from "../../Buttons/UpDownButton";
import { utils, writeFile } from "xlsx";
import UserContext from "../../../contexts/User";
import MeasuresContext from "../../../contexts/Measures";
import { TfiWorld } from "react-icons/tfi";
import { calculateAdjustment } from "../../../utils/calculationUtils";

const VATRules = (country, channel) => {
    if (["FR", "FF", "NL"].includes(country)) {
        return true;
    } else if (channel === "B2B") {
        return false;
    } else {
        return true;
    }
};

const Extrapfrom = (Version) => {
    return Version.split(">> Adjusted From : ");
};

const DetailedView = ({ filters, exportDetails, setExportDetails, Basket, setIsLoading }) => {
    const userPreferenceContext = useContext(UserContext);
    const { kpiSettings, IncServices } = useContext(UserContext);
    const { seriesBy, selectedAdjDeposit } = useContext(MeasuresContext);
    const [data, setData] = useState([]);
    const [data2Export, setData2Export] = useState([]);
    const [showspecs, setShowspecs] = useState(false);
    const [showoffers, setShowoffers] = useState(false);
    const [showadj, setShowadj] = useState(false);
    const [headers, setHeaders] = useState([]);
    const contentRef = useRef();
    const scrollRef = useRef();
    const [contentWidth, setContentWidth] = useState(0);
    const [showSummary, setShowSummary] = useState(userPreferenceContext?.user?.basket_sections?.Basket_Details);
    const { getCustlabelByCustId } = useAppContext();
    const [versionStdFilter, setVersionStdFilter] = useState(newPositiveAttributeFilter(Md.VersionStd, [Basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value)]));
    const custSlots = Object.entries(userPreferenceContext.defaultUserPref)
        .filter(([key, val]) => key.includes("customer_slot") && val !== null)
        .map(([key, val]) => val);
    const custIdFilters = newPositiveAttributeFilter(Md.CustId, custSlots);
    const { result: newresult, status: newstatus } = useExecutionDataView({
        execution: {
            seriesBy: [...seriesBy, Md.Deposit.Avg, Md.Emissions.Avg, /*33*/ Md.OtrPrice.Avg, /*34*/ Md.Apr.Avg, /*35*/ Md.FinanceContrib.Avg, /*36*/ Md.TotalCharges.Avg, /*37*/ Md.TotalPayable.Avg],
            slicesBy: [
                Md.Version,
                Md.Channel1,
                Md.CustId,
                Md.Ctryid,
                Md.VersionStd,
                Md.VehSegment,
                Md.Fuel,
                Md.BodyStyle,
                Md.VehType,
                Md.Size,
                Md.GearsPlus,
                Md.Drive,
                Md.Channel3,
                Md.Extraptype,
                Md.Duration,
                Md.Warning,
                Md.Url,
                Md.CorrMake,
                Md.CorrModel,
                Md.DateDatasets.DateOfScraping.Date.DdMmYyyy,
            ],
            filters: [...filters, versionStdFilter, custIdFilters],
            sortBy: [newAttributeSort(Md.CorrMake, "asc"), newAttributeSort(Md.CorrModel, "asc")], // Sort by Make and Model
        },
    });
    useEffect(() => {
        if (newstatus !== "loading") {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [newstatus, setIsLoading]);

    useEffect(() => {
        if (Basket !== undefined) {
            let versionStds = [];
            Basket.basketFilters
                .filter((basketFilter) => basketFilter.type === "version_std")
                .forEach((basketFilter) => {
                    versionStds.push(basketFilter.value);
                });
            setVersionStdFilter(newPositiveAttributeFilter(Md.VersionStd, versionStds));
        }
    }, [Basket]);

    useEffect(() => {
        if (newresult && newstatus === "success") {
            const slices = newresult?.data()?.slices().toArray();
            if (slices?.length > 0) {
                let predefined = [];
                if (selectedAdjDeposit !== "Total Payable by month") {
                    predefined = slices.filter((slice) => slice.dataPoints()[2].rawValue !== null);
                } else {
                    predefined = slices;
                }
                const flat = predefined?.map((slice) => {
                    const services = {
                        Maintenance: Number(slice.dataPoints()[5].rawValue),
                        Tyres: Number(slice.dataPoints()[7].rawValue),
                        Breakdown: Number(slice.dataPoints()[9].rawValue),
                        Replacement: Number(slice.dataPoints()[11].rawValue),
                        Insurance: Number(slice.dataPoints()[13].rawValue),
                        "Financial Loss": Number(slice.dataPoints()[15].rawValue),
                        "Winter Tyres": Number(slice.dataPoints()[17].rawValue),
                        "EV Bonus": Number(slice.dataPoints()[19].rawValue),
                        "Co2 Malus": Number(slice.dataPoints()[21].rawValue),
                    };
                    const composer = {
                        flag_ser: kpiSettings["flag_ser"],
                        flag_tyr: kpiSettings["flag_tyr"],
                        flag_bre: kpiSettings["flag_bre"],
                        flag_rep: kpiSettings["flag_rep"],
                        flag_ins: kpiSettings["flag_ins"],
                        flag_fin: kpiSettings["flag_fin"],
                        flag_win: kpiSettings["flag_win"],
                        flag_co2: kpiSettings["flag_co2"],
                        flag_evb: kpiSettings["flag_evb"],
                    };
                    return {
                        // stand alone
                        Make: slice.sliceTitles()[17],
                        Model: slice.sliceTitles()[18],
                        Version: Extrapfrom(slice.sliceTitles()[0])[0],
                        Player: getCustlabelByCustId(slice.sliceTitles()[2]),
                        Channel: slice.sliceTitles()[1],
                        Country: slice.sliceTitles()[3],
                        "List Price": Number(slice.dataPoints()[1].rawValue) > 0 ? Number(Number(slice.dataPoints()[1].rawValue).toFixed(0)) : null,
                        VAT: VATRules(slice.sliceTitles()[3], slice.sliceTitles()[1]) ? "Included" : "Excluded",
                        "OTR Price": Number(slice.dataPoints()[35].rawValue) > 0 ? Number(Number(slice.dataPoints()[35].rawValue).toFixed(0)) : null,
                        Specifications: "",
                        "Experteye Code": slice.sliceTitles()[4],
                        Segment: slice.sliceTitles()[5],
                        Energy: slice.sliceTitles()[6],
                        "Body Type": slice.sliceTitles()[7],
                        "PC/LCV": slice.sliceTitles()[8],
                        Size: slice.sliceTitles()[9],
                        Gearbox: slice.sliceTitles()[10],
                        Drive: slice.sliceTitles()[11],
                        "Power (HP)": Number(slice.dataPoints()[22].rawValue) > 0 ? Number(Number(slice.dataPoints()[22].rawValue).toFixed(0)) : null,
                        "Power (KW)": Number(slice.dataPoints()[29].rawValue) > 0 ? Number(Number(slice.dataPoints()[29].rawValue).toFixed(0)) : null,
                        CO2: Number(slice.dataPoints()[34].rawValue) > 0 ? Number(Number(slice.dataPoints()[34].rawValue).toFixed(0)) : null,
                        "Product Type": slice.sliceTitles()[12],
                        Quote: slice.sliceTitles()[13],
                        Duration: Number(slice.sliceTitles()[14].split("/")[0]), // Duration
                        "Mileage Total": Number(slice.sliceTitles()[14].split("/")[1]), // Mileage
                        // published
                        "Web Rental": Number(slice.dataPoints()[3].rawValue) > 0 ? Number(Number(slice.dataPoints()[3].rawValue).toFixed(0)) : null,
                        "Web Deposit": Number(slice.dataPoints()[33].rawValue) > 0 ? Number(Number(slice.dataPoints()[33].rawValue).toFixed(0)) : null,
                        "Offer details": "",
                        Date: slice.sliceTitles()[19],
                        "Valid until": null,
                        "Interest Rate": Number(slice.dataPoints()[seriesBy.length + 3].rawValue) > 0 ? (Number(slice.dataPoints()[seriesBy.length + 3].rawValue) * 100).toFixed(1) + "%" : null,
                        "RV (Purchase option)": Number(slice.dataPoints()[25].rawValue) > 0 ? Number(Number(slice.dataPoints()[25].rawValue).toFixed(0)) : null,
                        "RV% of List Price": Number(slice.dataPoints()[1].rawValue) > 0 ? (100 * (Number(slice.dataPoints()[25].rawValue) / Number(slice.dataPoints()[1].rawValue))).toFixed(1) + "%" : null,
                        "Total Credit Charges": Number(slice.dataPoints()[38].rawValue) > 0 ? Number(Number(slice.dataPoints()[38].rawValue).toFixed(0)) : null,
                        "Finance/OEM Contribution": Number(slice.dataPoints()[37].rawValue) > 0 ? Number(Number(slice.dataPoints()[37].rawValue).toFixed(0)) : null,
                        "Total Payable": Number(slice.dataPoints()[39].rawValue) > 0 ? Number(Number(slice.dataPoints()[39].rawValue).toFixed(0)) : null,
                        "Web Rental includes":
                            Object.entries(services)
                                .filter((m) => m[1] === 1)
                                .map((m) => m[0])
                                .join(", ") || "No Services",
                        Comments: slice.sliceTitles()[15],
                        Url: slice.sliceTitles()[16],
                        "Target Deposit": selectedAdjDeposit === "Total Payable by month" ? "Total Payable" : Number(selectedAdjDeposit),
                        Adjustements: "",
                        Maintenance: calculateAdjustment(slice, 4, composer["flag_ser"], IncServices),
                        Tyres: calculateAdjustment(slice, 6, composer["flag_tyr"], IncServices),
                        Breakdown: calculateAdjustment(slice, 8, composer["flag_bre"], IncServices),
                        "Replacement Car": calculateAdjustment(slice, 10, composer["flag_rep"], IncServices),
                        Insurance: calculateAdjustment(slice, 12, composer["flag_ins"], IncServices),
                        "Financial Loss": calculateAdjustment(slice, 14, composer["flag_fin"], IncServices),
                        "Deposit Adjustement": Number(slice.dataPoints()[2].rawValue) > 0 ? Number(Number(slice.dataPoints()[2].rawValue).toFixed(0)) : null,
                        "Extrapolated from": Extrapfrom(slice.sliceTitles()[0])[1] ?? null,
                        "Adjusted Rental": Number(slice.dataPoints()[23].rawValue) > 0 ? Number(Number(slice.dataPoints()[23].rawValue).toFixed(0)) : null,
                        "Leasing Factor": 100 * Number(slice.dataPoints()[24].rawValue) > 0 ? Number((100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2)) : null,
                    };
                });
                setData(flat);
                setData2Export(flat);
            }
        }
    }, [newresult, newstatus, getCustlabelByCustId, selectedAdjDeposit, kpiSettings]);

    function transposeArrayOfObjects(array) {
        // Get the attributes from the first object
        const attributes = Object.keys(array[0]);

        // Transpose the array
        const transposedArray = attributes.map((attribute) => {
            const values = array.map((obj) => obj[attribute]);
            return [attribute, ...values];
        });

        return transposedArray;
    }

    const handleExport = () => {
        const transposeddata = transposeArrayOfObjects(data2Export);
        const ws = utils.json_to_sheet(transposeddata, { skipHeader: true });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");
        writeFile(wb, `${Basket.name} Detailed Table.xlsx`);
        setExportDetails(false);
    };

    useEffect(() => {
        if (data.length > 0) {
            const keys = Object.keys(data[0]);
            let standardHeaders = keys.slice(1, 25);
            let publishedHeaders = keys.slice(25, 39);
            let adjustedHeaders = keys.slice(39);
            if (!showspecs) {
                standardHeaders = standardHeaders.filter((value) => {
                    return !keys.slice(keys.indexOf("Specifications") + 1, keys.indexOf("Product Type")).includes(value);
                });
            }
            if (!showoffers) {
                publishedHeaders = publishedHeaders.filter((value) => {
                    return !keys.slice(keys.indexOf("Offer details") + 1, keys.indexOf("Web Rental includes")).includes(value);
                });
            }
            if (!showadj) {
                adjustedHeaders = adjustedHeaders.filter((value) => {
                    return !keys.slice(keys.indexOf("Adjustements") + 1, keys.indexOf("Extrapolated from")).includes(value);
                });
            }
            setHeaders([standardHeaders, publishedHeaders, adjustedHeaders]);
        }
    }, [data, showadj, showoffers, showspecs]);

    const Tabheaders = [
        "Experteye Code",
        "Segment",
        "Energy",
        "Body Type",
        "PC/LCV",
        "Size",
        "Gearbox",
        "Drive",
        "Power (HP)",
        "Power (KW)",
        "CO2",
        "Date",
        "Valid until",
        "Interest Rate",
        "RV (Purchase option)",
        "RV% of List Price",
        "Total Credit Charges",
        "Finance/OEM Contribution",
        "Total Payable",
        "Maintenance",
        "Breakdown",
        "Replacement Car",
        "Insurance",
        "Financial Loss",
        "Deposit Adjustement",
    ];

    const syncScroll = (src, target) => {
        target.current.scrollLeft = src.current.scrollLeft;
    };
    //@ts-ignore
    const handleContentScroll = () => syncScroll(contentRef, scrollRef);
    //@ts-ignore
    const handleScrollScroll = () => syncScroll(scrollRef, contentRef);

    useEffect(() => {
        const contentElement = contentRef.current;
        const scrollElement = scrollRef.current;

        if (contentElement && scrollElement) {
            setContentWidth(contentElement?.scrollWidth);
            //@ts-ignore
            contentElement?.addEventListener("scroll", handleContentScroll);
            //@ts-ignore
            scrollElement?.addEventListener("scroll", handleScrollScroll);

            return () => {
                //@ts-ignore
                contentElement?.removeEventListener("scroll", handleContentScroll);
                //@ts-ignore
                scrollElement?.removeEventListener("scroll", handleScrollScroll);
            };
        }
    }, [handleContentScroll, handleScrollScroll, contentRef, scrollRef]);

    return (
        <>
            {newstatus === "success" && data.length > 0 && (
                <div className="my-4">
                    <div className="p-3 rounded-md flex justify-between items-stretch cursor-pointer hover:bg-gray-200" onClick={() => setShowSummary(!showSummary)}>
                        <span className="font-semibold text-lg text-steel">Detailed view in columns</span>
                        <UpDownButton visibilityState={showSummary} />
                    </div>
                    {showSummary && (
                        <>
                            <div ref={scrollRef} className="overflow-x-scroll h-5">
                                <div style={{ width: `${contentWidth}px` }} />
                            </div>
                            <div ref={contentRef} className="mt-2 shadow-inner overflow-scroll scrollbar-hide">
                                <table className="table-auto w-max h-4/5 overflow-auto">
                                    <thead className="sticky top-0 z-50">
                                        <tr>
                                            <th className="w-[48px] border" />
                                            <th className="sticky left-0 border w-[200px] bg-white text-xs text-left px-4">Make</th>
                                            {data.length > 0 &&
                                                data.map((item, index) => {
                                                    return <th className={`border border-gray-200 w-[200px] px-4 py-2 text-xs font-semibold ${index % 2 === 0 ? "bg-gray-200" : ""}`}>{item.Make}</th>;
                                                })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Standard Rows  */}
                                        <td rowSpan={headers[0]?.length + 1} className="border border-gray-200 font-bold left-0 bg-white"></td>
                                        {headers[0]?.map((header, index) => {
                                            if (header === "Specifications") {
                                                return (
                                                    <tr>
                                                        <td className="border text-gray-400 bg-white px-4 py-2 text-left text-xs sticky font-semibold left-0 cursor-pointer" onClick={() => setShowspecs(!showspecs)}>
                                                            {showspecs ? "( - )" : "( + )"} Specifications
                                                        </td>
                                                        {data.map((item, itemindex) => {
                                                            return <td className={`border w-48 ${itemindex % 2 === 0 ? "bg-gray-200" : ""}`}>{item[header]}</td>;
                                                        })}
                                                    </tr>
                                                );
                                            }
                                            return (
                                                <tr>
                                                    <td className={`border border-gray-200 pr-4 ${Tabheaders.includes(header) ? "pl-12" : "pl-4"} text-left text-xs sticky font-semibold left-0 bg-white`}>{header}</td>
                                                    {data.map((item, itemindex) => {
                                                        return <td className={`border border-gray-200 ${itemindex % 2 === 0 ? "bg-gray-200" : ""} w-48 max-w-xs px-4 text-center`}>{item[header]}</td>;
                                                    })}
                                                </tr>
                                            );
                                        })}
                                        {/* Published Rows */}
                                        <td rowSpan={headers[1]?.length + 1} className="bg-indigo-700 ">
                                            <span className="text-white text-base inline-block transform -rotate-90 origin-center">Published</span>
                                        </td>
                                        {headers[1]?.map((header, index) => {
                                            if (header === "Offer details") {
                                                return (
                                                    <tr>
                                                        <td className="border px-4 py-2 text-left text-xs sticky font-semibold left-0 bg-white text-gray-400 cursor-pointer" onClick={() => setShowoffers(!showoffers)}>
                                                            {showoffers ? "( - )" : "( + )"} Offer details
                                                        </td>
                                                        {data.map((item, itemindex) => {
                                                            return <td className={`border ${itemindex % 2 === 0 ? "bg-gray-200" : ""} w-48`}>{item[header]}</td>;
                                                        })}
                                                    </tr>
                                                );
                                            }
                                            return (
                                                <tr>
                                                    <td className={`border border-gray-200 pr-4 ${Tabheaders.includes(header) ? "pl-12" : "pl-4"} text-left text-xs sticky font-semibold left-0 bg-white`}>{header}</td>
                                                    {data.map((item, itemindex) => {
                                                        return (
                                                            <td className={`${itemindex % 2 === 0 ? "bg-gray-200" : ""} border border-gray-200 w-48 max-w-xs px-4 text-center`}>
                                                                {header === "Url" ? (
                                                                    <center>
                                                                        <a href={item[header]} className="text-steel" target="_blank" rel="noreferrer">
                                                                            <TfiWorld />
                                                                        </a>
                                                                    </center>
                                                                ) : (
                                                                    item[header]
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                        {/* Adjustments Rows */}
                                        <td rowSpan={headers[2]?.length + 1} className="bg-indigo-500 ">
                                            <span className="text-white text-base inline-block transform -rotate-90 origin-center">Adjusted</span>
                                        </td>
                                        {headers[2]?.map((header, index) => {
                                            if (header === "Adjustements") {
                                                return (
                                                    <tr>
                                                        <td className="border  px-4 py-2 text-left text-gray-400 text-xs sticky font-semibold left-0 bg-white cursor-pointer" onClick={() => setShowadj(!showadj)}>
                                                            {showadj ? "( - )" : "( + )"} Adjustements
                                                        </td>
                                                        {data.map((item, itemindex) => {
                                                            return <td className={`${itemindex % 2 === 0 ? "bg-gray-200" : ""} border w-48`}>{item[header]}</td>;
                                                        })}
                                                    </tr>
                                                );
                                            }
                                            return (
                                                <tr>
                                                    <td className={`border border-gray-200 pr-4 ${Tabheaders.includes(header) ? "pl-12" : "pl-4"} text-left text-xs sticky font-semibold left-0 bg-white`}>{header}</td>
                                                    {data.map((item, itemindex) => {
                                                        return <td className={`${itemindex % 2 === 0 ? "bg-gray-200" : ""} border border-gray-200 w-48 max-w-xs px-4 text-center`}>{item[header]}</td>;
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            )}
            {exportDetails && handleExport()}
        </>
    );
};

export default DetailedView;
