import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useEffect, useState } from "react";
import { newJsonPivot } from "../../utils/jsonPivot";
import { base100, calculatePriceIndexBase100 } from "../../utils/indices";
import { colors } from "../../constants";
import { useAppContext } from "../../contexts/AppContext";
import * as Md from "../../md/full";

const IndiceWidget = ({ id, execDefinition, componentChild, ...props }) => {
    const { getColorByCustId, getParamsByCustID } = useAppContext();
    const [data, setData] = useState([]);
    const [categoryColors, setCategoryColors] = useState([]);
    const { result, status, error } = useExecutionDataView({
        execution: execDefinition,
        // execution: { seriesBy, slicesBy: [Md.MthCode, displayMode.attribute], filters: [...currentFilterHistory, newPositiveAttributeFilter(Md.FlagPrevMth, ["1"]), newPositiveAttributeFilter(Md.MthCode, monthlist)] },
    });

    const displayMode = {
        key: "Player",
        title: "Player",
        label: "Player",
        formatTitle: (value, ctryId) => getParamsByCustID(value, ctryId)?.custLabel,
        attribute: Md.CustId,
    };

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        if (slices) {
            const prePivot = slices?.map((slice) => ({
                mthCode: slice.sliceTitles()[0],
                [displayMode.key]: slice.sliceTitles()[1],
                Value: id === "volume-indices" ? (Number(slice.dataPoints()[27].rawValue) !== 0 ? Number(slice.dataPoints()[28].rawValue) : null) : 100 * Number(slice.dataPoints()[27].rawValue),
            }));
            const categoryColors = result
                ?.meta()
                .allHeaders()[0][1]
                .reduce((acc, nextHeader, index) => {
                    const headerTitle = nextHeader?.attributeHeaderItem?.name;
                    if (!acc.find((item) => item?.name === headerTitle)) {
                        acc.push(
                            Object.assign({
                                name: headerTitle,
                                color: displayMode.key === "Player" ? getColorByCustId(headerTitle) : colors[index],
                            }),
                        );
                    }
                    return acc;
                }, []);
            const data = newJsonPivot(prePivot, { row: displayMode.key, column: "mthCode", value: "Value" });
            setData(data);
            setCategoryColors(categoryColors);
        }
    }, [result]);

    return status === "success" && data.length > 0 ? (
        React.createElement(componentChild, { data, categoryColors, calculation: calculatePriceIndexBase100, startMonth: props.startMonth, displayBy: displayMode.key, dpTitleHandler: displayMode.formatTitle, ...props })
    ) : status === "loading" ? (
        <LoadingComponent />
    ) : (
        <ErrorComponent message={error?.message} />
    );
};

export default IndiceWidget;
