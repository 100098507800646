import React, { useContext, useEffect, useState, useRef } from "react";

import HighchartsComponent from "../HighchartsComponent";
import { calculateAvg } from "../../utils/calculationUtils";
import FiltersContext from "../../contexts/Filters";

const CumulatedIndices = ({ data, calculation, startMonth, displayBy, dpTitleHandler = (value, ctryid) => {}, defaultCategoryColor = undefined, categoryColors = [], filters, ...props }) => {
    const id = "cumul-indices";
    const { getFirstFilterValue } = useContext(FiltersContext);
    const ctryId = getFirstFilterValue("filterCountryId");
    const [dataPoints, setDataPoints] = useState([]);
    const [categories, setCategories] = useState([]);
    const chartRef = useRef(null);

    // Calculating the average
    const filtereddata = data.map((m) => {
        const obj = {};
        Object.keys(m).forEach((key) => {
            if (m[key] !== null) {
                obj[key] = m[key];
            }
        });
        return obj;
    });
    const MonthList = data.map((m) => {
        const obj = [];
        Object.keys(m).forEach((key) => {
            if (key !== displayBy && !obj.includes(key)) {
                obj.push(key);
            }
        });
        return obj;
    })[0];

    const avgToBase100 = MonthList.reduce((acc, mth, index) => {
        const mthValues = filtereddata.reduce((acc, elem) => {
            if (mth in elem && !isNaN(elem[mth])) acc.push(Number(elem[mth]));
            return acc;
        }, []);

        const avgValue =
            index !== 0
                ? acc[index - 1] * (1 + (calculateAvg(mthValues) / 100 || 0))
                : // (mthValues.length === 1 ? acc[index - 1] + (calculateAvg(mthValues) || 0) :
                  // acc[index - 1] * (1 + (calculateAvg(mthValues)/100 || 0)) )
                  100;
        acc.push(Number(avgValue));
        return acc;
    }, []);

    useEffect(() => {
        if (startMonth !== null) {
            const dataParsed = data
                .map((elem) => {
                    let pctValues = calculation(elem, startMonth).filter((val) => val !== null);
                    const accumulator = pctValues[pctValues.length - 1] - pctValues[0];

                    return Object.freeze({
                        name: elem[displayBy],
                        high: Number(Number(accumulator).toFixed(1)) > 0 ? Number(Number(accumulator).toFixed(1)) : 0,
                        low: Number(Number(accumulator).toFixed(1)) < 0 ? Number(Number(accumulator).toFixed(1)) : 0,
                        color: categoryColors.find((item) => item.name === elem[displayBy])?.color,
                    });
                })
                .filter((elem) => elem !== null && (elem.low !== 0 || elem.high !== 0))
                .sort((a, b) => (a.low === 0 && b.low === 0 ? b.high - a.high : b.low - a.low));
            dataParsed.splice(
                0,
                0,
                Object.freeze({
                    name: "Average",
                    low: 0,
                    high: Number(avgToBase100[avgToBase100.length - 1] - 100).toFixed(1),
                    color: "#506e96",
                }),
            );
            setDataPoints(dataParsed);
            setCategories(dataParsed.map((elem) => elem.name));
        }
    }, [startMonth, data]);

    return (
        <div ref={chartRef}>
            <HighchartsComponent
                widgetProps={{
                    id,
                    filters,
                    section: "trends",
                }}
                options={{
                    chart: {
                        type: "columnrange",
                        inverted: true,
                        height: props.height || (categories?.length > 5 ? (categories.length <= 10 ? categories.length * 50 : categories.length * 40) : 300),
                        width: chartRef?.current?.getBoundingClientRect().width,
                    },
                    plotOptions: {
                        columnrange: {
                            // enableMouseTracking: false,
                            pointWidth: categories.length < 10 ? 28 : 24,
                            dataLabels: {
                                enabled: true,
                                align: "left",
                                formatter: function () {
                                    if (this.y !== 0) {
                                        // this.series.options.dataLabels.align = "left";
                                        return `${this.y} %`;
                                    }
                                },
                            },
                        },
                    },
                    title: {
                        text: null,
                    },
                    xAxis: {
                        categories: [...categories],
                        // offset: 5,
                        labels: {
                            align: "center",
                            x: -20,
                            useHTML: true,
                            formatter: ({ value }) => {
                                return displayBy !== "Player" || value === "Average" ? `<div style="font-size:14px; font-weight: 600; color: #506e96">${value}</div>` : `<div style="width:70px; height:20px;"><img src="/images/logos/Logo_${value}.png" width="70" height="70" /></div>`;
                            },
                        },
                    },
                    yAxis: {
                        title: {
                            text: "Price evolution ( % ) ",
                        },
                    },
                    legend: {
                        enabled: false,
                    },
                    tooltip: {
                        formatter: function () {
                            return `<b>${this.x.name !== "Average" ? dpTitleHandler(this.x.name, ctryId) : "Average"}</b>: ${this.point.high !== 0 ? this.point.high : this.point.low} %`;
                        },
                    },
                    series: {
                        name: "Cumul indices",
                        data: dataPoints,
                    },
                }}
                {...props}
            />
        </div>
    );
};

export { CumulatedIndices };
