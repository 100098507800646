import React, { useContext } from "react";
import { measureLocalId, newAttributeSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { useLocation } from "react-router-dom";

import FiltersContext from "../../contexts/Filters";
import SelectKpi from "./SelectKpi";
import * as Md from "../../md/full";
import AttributeFilterWithCustomizations from "../AttributeFilterWithCustomizations";
import ComboBoxScopeFilter from "../ComboBoxScopeFilter";
import ComboBoxScopeCountryFilter from "../ComboBoxScopeCountryFilter";
import { useAppContext } from "../../contexts/AppContext";
import { useBasketContext } from "../../contexts/BasketProvider";
import { FilterMenu } from "../FilterMenu";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useState } from "react";
import MeasuresContext from "../../contexts/Measures";
import MesureFilterCustomizations from "../MesureFilterCustomizations";
import SelectAdjDeposit from "./SelectAdjDeposit";
import Parameters from "./Parameters";

function Query({ showScope = true, showKpi = true, showParams = true, showFilters = true, MonthScope = true, Channel1Scope = true, DurationScope = true, shownews = true, moreOptions = false, enableChannel3 = true, enableQouteType = true }) {
    const { setShowMoreFilters, showMoreFilters } = useAppContext();
    const { selectBasketFilters, selectedBasketFilters, systemBaskets, userBaskets } = useBasketContext();
    // State used to handle viewing/collapse the settings related to filters
    const [showMore, setShowMore] = useState(false);
    const {
        filterCountryId,
        filterMthCode,
        filterChannel1,
        filterChannel3,
        filterDuration,
        filterMake,
        filterModel,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterFuel,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterListPrice,
        filterPs,
        filterKw,
        filterCo2,
        filterCustmix,
        currentFilter,
        updateFilter,
        clearFilters,
        getFirstFilterValue,
        loading,
    } = useContext(FiltersContext);
    const { selectedKpi, updateMeasures } = useContext(MeasuresContext);
    const location = useLocation();

    const { result } = useExecutionDataView({
        execution: {
            seriesBy: [Md.FinRental.Avg, Md.Co2malus.Avg, Md.Ps.Avg, Md.ListPrice.Avg, Md.Kw.Avg],
            slicesBy: [Md.MthCode],
            filters: [filterCountryId, filterChannel1, filterChannel3, filterDuration],
            sortBy: [newAttributeSort(Md.MthCode, "desc")],
        },
    });

    const filterItems = [
        {
            mdAttribute: Md.CorrMake,
            filterName: "filterMake",
            filterTitle: "Make",
            filterAttribute: filterMake,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.CorrModel,
            filterName: "filterModel",
            filterTitle: "Model",
            filterAttribute: filterModel,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.Fuel,
            filterName: "filterFuel",
            filterTitle: "Energy",
            filterAttribute: filterFuel,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.TrimName,
            filterName: "filterTrim",
            filterTitle: "Trim",
            filterAttribute: filterTrim,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.VehSegment,
            filterName: "filterVehSegment",
            filterTitle: "Segment",
            filterAttribute: filterVehSegment,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.BodyStyle,
            filterName: "filterBody",
            filterTitle: "Body",
            filterAttribute: filterBody,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.VehType,
            filterName: "filterVehType",
            filterTitle: "PC/LCV",
            filterAttribute: filterVehType,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.Size,
            filterName: "filterSize",
            filterTitle: "Size",
            filterAttribute: filterSize,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.GearsPlus,
            filterName: "filterGearPlus",
            filterTitle: "Gearbox",
            filterAttribute: filterGearPlus,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.Drive,
            filterName: "filterDrive",
            filterTitle: "Drive",
            filterAttribute: filterDrive,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.Channel3,
            filterName: "filterChannel3",
            filterTitle: "Product",
            filterAttribute: filterChannel3,
            enable: enableChannel3,
            onUpdate: updateFilter,
        },
        {
            mdAttribute: Md.CustLabel,
            filterName: "filterCustLabel",
            filterTitle: "Player",
            filterAttribute: filterCustLabel,
            onUpdate: updateFilter,
        },
        // {
        //     mdAttribute: Md.Extraptype,
        //     filterName: "filterExtrapType",
        //     filterTitle: "Quote",
        //     filterAttribute: filterExtrapType,
        //     enable: enableQouteType,
        //     onUpdate: updateFilter,
        // },
        {
            mdAttribute: Md.Custmixindex,
            filterName: "filterCustmix",
            filterTitle: "Version Mix",
            filterAttribute: filterCustmix,
            onUpdate: updateFilter,
        },
    ];

    const measureFilterItems = [
        {
            measureIdentifier: measureLocalId(Md.ListPrice.Avg),
            filterAttribute: filterListPrice,
            title: "List Price",
            onApply: (e) => updateFilter("filterListPrice", e),
        },
        {
            measureIdentifier: measureLocalId(Md.Ps.Avg),
            filterAttribute: filterPs,
            title: "Power",
            onApply: (e) => updateFilter("filterPs", e),
        },
        {
            measureIdentifier: measureLocalId(Md.Kw.Avg),
            filterAttribute: filterKw,
            title: "KW",
            onApply: (e) => updateFilter("filterKw", e),
        },
        {
            measureIdentifier: measureLocalId(Md.Co2malus.Avg),
            filterAttribute: filterCo2,
            title: "CO2",
            onApply: (e) => updateFilter("filterCo2", e),
        },
    ];

    const activeFilters = filterItems.filter((item) => location.pathname !== "/brand_model_analysis" || !["filterMake", "filterModel"].includes(item.filterName));

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        if (slices) {
            // Initialize Mth code with the max value from the filters selection
            updateFilter("filterMthCode", newPositiveAttributeFilter(Md.MthCode, [slices[0].sliceTitles()[0]]));
        }
    }, [result, updateFilter]);

    const onSelectBaskets = (baskets) => {
        // Update filter by associated versions std
        const allVersionStds = baskets
            ?.map((elem) => elem.basketFilters)
            .reduce((acc, nextVal) => {
                let versionStds = nextVal.filter((basketFilter) => basketFilter.type === "version_std").map((basketFilter) => basketFilter.value);
                acc.push(...versionStds);
                return acc;
            }, []);
        if (allVersionStds) {
            selectBasketFilters(baskets);
            updateFilter("filterVersionStd", newPositiveAttributeFilter(Md.VersionStd, allVersionStds));
        }
    };

    // const scopes = [
    //     DurationScope && {
    //         title: "Duration",
    //         filterAttribute: filterDuration,
    //         filterID: "filterDuration",
    //         mdAttribute: Md.Duration,
    //         value: getFirstFilterValue("filterDuration"),
    //     },
    //     Channel1Scope && {
    //         title: "Channel 1",
    //         filterAttribute: filterChannel1,
    //         filterID: "filterChannel1",
    //         mdAttribute: Md.Channel1,
    //         value: getFirstFilterValue("filterChannel1"),
    //     },
    //     MonthScope && {
    //         title: "Month",
    //         filterAttribute: filterMthCode,
    //         filterID: "filterMthCode",
    //         mdAttribute: Md.MthCode,
    //         value: getFirstFilterValue("filterMthCode"),
    //     },
    // ];

    return (
        <div className="mt-2 flex flex-col gap-4 items-stretch">
            {showScope && !loading && (
                <div className="flex items-center gap-1">
                    <div className="w-24">
                        <label className="font-bold text-base text-indigo-700">Scope</label>
                    </div>
                    <div className="flex items-center gap-3">
                        <ComboBoxScopeCountryFilter title="Country" value={getFirstFilterValue("filterCountryId")} mdAttribute={Md.Ctryid} filterID="filterCountryId" />
                        {/* {!loading && scopes.map((scope, index) => <ComboBoxScopeFilter key={index} title={scope.title} value={scope.value} filterAttribute={scope.filterAttribute} mdAttribute={scope.mdAttribute} filterID={scope.filterID} currentFilter={currentFilter} />)} */}
                        {DurationScope && <ComboBoxScopeFilter title={"Duration"} value={getFirstFilterValue("filterDuration")} filterAttribute={filterDuration} mdAttribute={Md.Duration} filterID={"filterDuration"} currentFilter={currentFilter} onUpdateFilter={updateFilter} />}
                        {Channel1Scope && <ComboBoxScopeFilter title={"Channel 1"} value={getFirstFilterValue("filterChannel1")} filterAttribute={filterChannel1} mdAttribute={Md.Channel1} filterID={"filterChannel1"} currentFilter={currentFilter} onUpdateFilter={updateFilter} />}
                        {MonthScope && <ComboBoxScopeFilter title={"Month"} value={getFirstFilterValue("filterMthCode")} filterAttribute={filterMthCode} mdAttribute={Md.MthCode} filterID={"filterMthCode"} currentFilter={currentFilter} onUpdateFilter={updateFilter} />}
                        {showKpi && <SelectKpi title={"Select KPI"} selectedKpi={selectedKpi} onSelect={(obj) => updateMeasures("selectedKpi", obj)} />}
                        {getFirstFilterValue("filterCountryId") === "GB" && location.pathname !== "/trends" && <SelectAdjDeposit title="Target deposit" />}
                        <div className="flex items-center"></div>
                        {moreOptions && (
                            <span className="cursor-pointer flex items-center w-fit rounded px-2 py-1 gap-2 text-steel hover:underline hover:bg-backgroundColor" onClick={() => setShowMore(!showMore)}>
                                <DownOutlined /> More filters
                            </span>
                        )}
                    </div>
                </div>
            )}

            {!loading && showFilters && (
                <>
                    <div className="flex gap-1">
                        <div className="w-24">
                            <label className="font-bold text-base text-indigo-700">Filters</label>
                        </div>
                        <div className="grid grid-cols-filters gap-3">
                            {activeFilters
                                .slice(0, 7)
                                .map(
                                    (item, index) =>
                                        (isNaN(item?.enable) || item.enable) && (
                                            <AttributeFilterWithCustomizations
                                                key={index}
                                                slicesBy={[item.mdAttribute]}
                                                filter={item.filterAttribute}
                                                title={item.filterTitle}
                                                currentFilter={currentFilter}
                                                updateFilter={item.onUpdate}
                                                filterName={item.filterName}
                                                isDisabled={location.pathname === "brand_model_analysis"}
                                            />
                                        ),
                                )}
                            <FilterMenu dataElements={[...userBaskets, ...systemBaskets]} defaultElements={selectedBasketFilters} dataLabelProp={"name"} groupOptionsLabel={"group"} onSelect={onSelectBaskets} onClear={() => selectBasketFilters([])} />
                            {showMoreFilters && (
                                <div
                                    className={
                                        "col-span-8 grid grid-cols-filters gap-3"
                                        //  + (animateFilters ? "animate-[smoothIn_0.7s]" : "")
                                    }
                                >
                                    {activeFilters
                                        .slice(7)
                                        .map(
                                            (item, index) =>
                                                (isNaN(item?.enable) || item.enable) && (
                                                    <AttributeFilterWithCustomizations key={index} slicesBy={[item.mdAttribute]} filter={item.filterAttribute} title={item.filterTitle} currentFilter={currentFilter} updateFilter={item.onUpdate} filterName={item.filterName} />
                                                ),
                                        )}
                                    {measureFilterItems.map((item, index) => (
                                        <MesureFilterCustomizations key={index} title={item.title} onApply={item.onApply} filter={item.filterAttribute} measureIdentifier={item.measureIdentifier} />
                                    ))}
                                </div>
                            )}
                        </div>
                        <button className="self-start w-24 max-h-7 text-steel underline py-0.5 text-sm font-semibold" onClick={() => setShowMoreFilters(!showMoreFilters)}>
                            {showMoreFilters ? "Less" : "More"} filters
                        </button>
                        <button
                            className="ml-2 w-max max-h-7 border-gray-300 bg-secondary-500 hover:bg-red text-sm text-white px-2.5 py-0.5 border rounded font-semibold shadow-button"
                            onClick={() => {
                                selectBasketFilters(undefined);
                                clearFilters();
                            }}
                        >
                            Clear filters
                        </button>
                    </div>
                </>
            )}
            {!loading && showParams && <Parameters />}
        </div>
    );
}

export default Query;
