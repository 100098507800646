import React from "react";
import HighchartsComponent from "../HighchartsComponent";
import { useAppContext } from "../../contexts/AppContext";

const defaultLegendStyle = {
    fontSize: "10px",
    fontWeigth: "600",
    color: "#595959",
};
const defaultMarkerConfig = {
    enabled: true,
    symbol: "circle",
};

export function TrendChart({
    services,
    changesByMonth = {},
    title,
    subtitle = undefined,
    categories,
    series,
    width = null,
    height = null,
    minValue = undefined,
    maxValue = undefined,
    marginBottom = 120,
    pdfMode = false,
    referenceLine = undefined,
    enableLegend = true,
    legendStyle = defaultLegendStyle,
    markerConfig = defaultMarkerConfig,
    screenshotName = "trends",
    metadata,
    filters,
    id,
    section,
    ...props
}) {
    const { getCustlabelByCustId } = useAppContext();
    return (
        <HighchartsComponent
            widgetProps={{
                id,
                filters,
                section,
                ...metadata,
            }}
            options={{
                chart: {
                    type: "spline",
                    width: width,
                    height: height,
                    marginBottom: marginBottom,
                    spacingBottom: 2,
                },
                plotOptions: {
                    series: {
                        marker: markerConfig,
                        dataLabels: {
                            enabled: true,
                        },
                        connectNulls: true,
                        // enableMouseTracking: mouseTracking,
                    },
                },
                tooltip: {
                    useHTML: true,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    shadow: true,
                    style: {
                        fontSize: "12px",
                        color: "#333",
                    },
                    formatter: function () {
                        let s = '<table style="border-collapse: collapse; width: 100%;">';
                        s += `<tr><th colspan="2" style="padding-bottom: 5px;"><b>${this.x}</b></th></tr>`;
                        s += `<tr>
                                <td style="text-align: left; padding: 2px;"><b>${this.series.name}:</b></td>
                                <td style="text-align: right; padding: 2px;">${this.y}</td>
                            </tr>`;

                        const groupKey = this.series.name.trim();
                        const secondaryKey = title.split("-")[0].trim();
                        const monthChanges = changesByMonth[groupKey]?.[this.x];

                        if (monthChanges && monthChanges[secondaryKey]) {
                            Object.entries(monthChanges[secondaryKey]).forEach(([measure, value]) => {
                                s += `<tr>
                                        <td style="text-align: left; padding: 2px;"><b>${measure}:</b></td>
                                        <td style="text-align: right; padding: 2px;">${value}</td>
                                    </tr>`;
                            });
                        }

                        s += "</table>";
                        return s;
                    },
                },
                legend: {
                    enabled: enableLegend,
                    // verticalAlign: 'middle',
                    // align: 'right',
                    padding: 12,
                    itemStyle: legendStyle,
                },
                title: {
                    text: title,
                    style: {
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#595959",
                    },
                },
                subtitle: {
                    text: subtitle,
                    align: "center",
                    style: {
                        fontWeight: 500,
                        fontStyle: "italic",
                    },
                },
                xAxis: {
                    categories: categories,
                    crosshair: true,
                },
                yAxis: {
                    min: minValue,
                    max: maxValue,
                    title: {
                        text: null,
                    },
                    plotLines: referenceLine && [
                        {
                            value: referenceLine.value,
                            color: "#FF0000",
                            dashStyle: "shortdash",
                            width: 2,
                            zIndex: 10,
                        },
                    ],
                },
                series: series.map((serie) => {
                    if (serie.name.length === 3) {
                        return {
                            ...serie,
                            name: getCustlabelByCustId(serie.name),
                        };
                    } else {
                        return serie;
                    }
                }),
                lang: {
                    noData: "No data available",
                },
                noData: {
                    style: {
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#595959",
                    },
                },
            }}
            enableScreenshot={!pdfMode}
            imgName={screenshotName}
            {...props}
        />
    );
}
