import React, { useContext } from "react";
import HighchartsComponent from "../HighchartsComponent";
import Highcharts from "highcharts";
import { calculateAvg } from "../../utils/calculationUtils";
import FiltersContext from "../../contexts/Filters";

export default function TrendIndices({ filters, data, calculation, startMonth, displayBy, dpTitleHandler = (value, ctryid) => {}, categoryColors = [], ...props }) {
    const id = "trend-line";
    const { getFilterValue } = useContext(FiltersContext);
    const ctryId = getFilterValue("filterCountryId")[0];
    // const currency = ctryId !== "UK" ? "€" : "£";
    const filtereddata = data.map((m) => {
        const obj = {};
        Object.keys(m).forEach((key) => {
            if (m[key] !== null) {
                obj[key] = m[key];
            }
        });
        return obj;
    });

    const MonthList = data.map((m) => {
        const obj = [];
        Object.keys(m).forEach((key) => {
            if (key !== displayBy && !obj.includes(key)) {
                obj.push(key);
            }
        });
        return obj;
    })[0];
    const lines = data.map((m) =>
        Object.assign({
            name: dpTitleHandler(m[displayBy], ctryId) || m[displayBy],
            data: calculation(m, startMonth),
            color: categoryColors.find((item) => item.name === m[displayBy])?.color,
            dashStyle: "solid",
        }),
    );
    const avgToBase100 = MonthList.reduce((acc, mth, index) => {
        const mthValues = filtereddata.reduce((acc, elem) => {
            if (mth in elem && !isNaN(elem[mth])) acc.push(Number(elem[mth]));
            return acc;
        }, []);
        const avgValue =
            index !== 0
                ? acc[index - 1] * (1 + (calculateAvg(mthValues) / 100 || 0))
                : // (mthValues.length === 1 ? acc[index - 1] + (calculateAvg(mthValues) || 0) :
                  // acc[index - 1] * (1 + (calculateAvg(mthValues)/100 || 0)) )
                  100;
        acc.push(Number(avgValue));
        return acc;
    }, []);
    // calculating lines diff percentage vis-a-vis previous month
    const diff = lines.map((m) => {
        const baseIndex = m.data.findIndex((dp) => dp !== null);
        return m.data.map((x, i) => (i === baseIndex || x === null || m.data[i - 1] === null ? null : ((x - m.data[i - 1]) / m.data[i - 1]) * 100));
    });

    return (
        <HighchartsComponent
            widgetProps={{
                id,
                filters,
                startMonth,
                section: "trends",
            }}
            options={{
                chart: {
                    type: "spline",
                    height: props.height || 500,
                },
                title: {
                    text: "",
                    style: {
                        display: "none",
                    },
                },
                subtitle: {
                    text: "",
                    style: {
                        display: "none",
                    },
                },
                xAxis: {
                    categories: Object.keys(data[0]).filter((f) => f !== displayBy),
                    crosshair: true,
                },
                yAxis: {
                    title: {
                        text: "",
                    },
                },
                tooltip: {
                    formatter: function () {
                        const baseIndex = lines.find((item) => item.name === this.series.name)?.data.findIndex((dp) => dp !== null);
                        return this.series.name !== "Average"
                            ? `<b>${this.point.category} <br/>${this.series.name} </b>: ${Highcharts.numberFormat(this.y, 1)}` +
                                  (baseIndex !== this.point.index && diff[this.series.index][this.point.index] !== null
                                      ? `<br/><b>vs ${MonthList[this.point.index - 1]} : </b> ${diff[this.series.index][this.point.index] > 0 ? "+" : ""}${Highcharts.numberFormat(diff[this.series.index][this.point.index], 1)}%`
                                      : "")
                            : `<b>${this.point.category} <br/>${this.series.name}</b>: ${Highcharts.numberFormat(this.y, 1)}`;
                    },
                },
                series: [...lines, { name: "Average", data: avgToBase100, color: "#506e96", dashStyle: "Dash" }],
                plotOptions: {
                    series: {
                        connectNulls: true,
                    },
                },
            }}
            {...props}
        />
    );
}
