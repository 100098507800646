import React, { createContext, useState, useCallback, useContext, useEffect } from "react";

import { newArithmeticMeasure } from "@gooddata/sdk-model";

import * as Md from "../md/full";
import UserContext from "./User";
import FiltersContext from "./Filters";

const kpiLists = [
    {
        id: 1,
        name: "Adj. Rental",
        index: 23,
        roundValue: (value) => Math.round(Number(value)),
    },
    {
        id: 2,
        name: "Web Price",
        index: 3,
        roundValue: (value) => Math.round(Number(value)),
    },
    {
        id: 3,
        name: "List Price",
        index: 1,
        roundValue: (value) => (value !== 0 ? Math.round(Number(value)) : null),
    },
    {
        id: 4,
        name: "Leasing Factor",
        index: 24,
        roundValue: (value) => (Number(Number(Math.round(100 * Number(value)) / 100).toFixed(2)) > 0 ? Number(Number(Math.round(100 * Number(value)) / 100).toFixed(2)) : null),
    },
];

const MeasuresContext = createContext(null);

const countriesMap = (code) => {
    switch (code) {
        case "BE":
            return "Belgium";
        case "DE":
            return "Germany";
        case "ES":
            return "Spain";
        case "FR":
            return "France";
        case "FF":
            return "France";
        case "IT":
            return "Italy";
        case "NL":
            return "Netherlands";
        case "UK":
            return "United Kingdom";
        case "GB":
            return "United Kingdom";
        default:
            return "UNKNOWN";
    }
};

const flagServiceMap = {
    flag_ser: Md.Service.Avg,
    flag_tyr: Md.Tyres.Avg,
    flag_bre: Md.Breakdown.Avg,
    flag_rep: Md.ReplacementVehicle.Avg,
    flag_ins: Md.Insurance.Avg,
    flag_fin: Md.Financialloss.Avg,
    flag_win: Md.Wintertyres.Avg,
    flag_co2: Md.Co2malus.Avg,
    flag_evb: Md.Evbonus.Avg,
};

const Deposit = {
    "Total Payable by month": Md.AdjDeposit.Avg,
    0: Md.AdjDeposit0.Avg,
    1000: Md.AdjDeposit1000.Avg,
    2000: Md.AdjDeposit2000.Avg,
    2500: Md.AdjDeposit2500.Avg,
    3000: Md.AdjDeposit3000.Avg,
    5000: Md.AdjDeposit5000.Avg,
};

const MeasuresProvider = ({ children }) => {
    const { kpiSettings, IncServices } = useContext(UserContext);
    const { loading, getFirstFilterValue } = useContext(FiltersContext);
    const [selectedAdjDeposit, setSelectedAdjDeposit] = useState("Total Payable by month");
    const country = getFirstFilterValue("filterCountryId");
    const pathname = window.location.pathname;

    const [measures, setMeasures] = useState({
        seriesBy: [
            Md.FinRental.Avg,
            Md.ListPrice.Avg,
            Deposit[selectedAdjDeposit],
            Md.Rental.Avg,
            // Flags and Amounts
            Md.Service.Avg, // 4
            Md.FlService.Avg,
            Md.Tyres.Avg,
            Md.FlTyres.Avg,
            Md.Breakdown.Avg,
            Md.FlBreakdown.Avg,
            Md.ReplacementVehicle.Avg,
            Md.FlReplacement.Avg,
            Md.Insurance.Avg,
            Md.FlInsurance.Avg,
            Md.Financialloss.Avg,
            Md.FlFinancialloss.Avg,
            Md.Wintertyres.Avg,
            Md.FlWintertyres.Avg,
            Md.Evbonus.Avg,
            Md.FlEvbonus.Avg,
            Md.Co2malus.Avg, //20
            Md.FlCo2malus.Avg,

            // User in filters
            Md.Ps.Avg,

            // Complex metrics
            // 23 Adjusted Rental
            newArithmeticMeasure([Md.FinRental.Avg, Deposit[selectedAdjDeposit], Md.Service.Avg, Md.Tyres.Avg, Md.Breakdown.Avg, Md.ReplacementVehicle.Avg, Md.Insurance.Avg, Md.Financialloss.Avg, Md.Wintertyres.Avg, Md.Co2malus.Avg, Md.Evbonus.Avg], "sum"),
            newArithmeticMeasure(
                [newArithmeticMeasure([Md.FinRental.Avg, Deposit[selectedAdjDeposit], Md.Service.Avg, Md.Tyres.Avg, Md.Breakdown.Avg, Md.ReplacementVehicle.Avg, Md.Insurance.Avg, Md.Financialloss.Avg, Md.Wintertyres.Avg, Md.Co2malus.Avg, Md.Evbonus.Avg], "sum"), Md.ListPrice.Avg],
                "ratio",
            ),
            Md.Rv.Avg,
            Md.Discount.Avg,
            Md.Evolpct.Avg,
            Md.VersionStdCount, // 28
            Md.Kw.Avg, //29
            Md.VersionCount, //30
            Md.Latestm.Avg,
            Md.Latestw.Avg,
        ],
        availableKpi: kpiLists,
        selectedKpi: kpiLists[0],
        updateMeasures: null,
        getScopeForTitle: null,
        getScopeForTitleHistory: null,
    });

    useEffect(() => {
        if (!loading) {
            if (kpiSettings && Object.keys(kpiSettings).length > 0) {
                const options = Object.keys(kpiSettings)
                    .filter((f) => kpiSettings[f] === 1)
                    .map((m) => flagServiceMap[m]);
                setMeasures((prevState) => ({
                    ...prevState,
                    seriesBy: [
                        Md.FinRental.Avg,
                        Md.ListPrice.Avg,
                        Deposit[selectedAdjDeposit],
                        Md.Rental.Avg,
                        Md.Service.Avg,
                        Md.FlService.Avg,
                        Md.Tyres.Avg,
                        Md.FlTyres.Avg,
                        Md.Breakdown.Avg,
                        Md.FlBreakdown.Avg,
                        Md.ReplacementVehicle.Avg,
                        Md.FlReplacement.Avg,
                        Md.Insurance.Avg,
                        Md.FlInsurance.Avg,
                        Md.Financialloss.Avg,
                        Md.FlFinancialloss.Avg,
                        Md.Wintertyres.Avg,
                        Md.FlWintertyres.Avg,
                        Md.Evbonus.Avg,
                        Md.FlEvbonus.Avg,
                        Md.Co2malus.Avg,
                        Md.FlCo2malus.Avg,
                        Md.Ps.Avg,
                        IncServices ? newArithmeticMeasure([Md.FinRental.Avg, Deposit[selectedAdjDeposit], ...options], "sum") : newArithmeticMeasure([Md.Rental.Avg, Deposit[selectedAdjDeposit]], "sum"),
                        IncServices
                            ? newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Deposit[selectedAdjDeposit], ...options], "sum"), Md.ListPrice.Avg], "ratio")
                            : newArithmeticMeasure([newArithmeticMeasure([Md.Rental.Avg, Deposit[selectedAdjDeposit]], "sum"), Md.ListPrice.Avg], "ratio"),
                        Md.Rv.Avg,
                        Md.Discount.Avg,
                        Md.Evolpct.Avg,
                        Md.VersionStdCount,
                        Md.Kw.Avg,
                        Md.VersionCount,
                        Md.Latestm.Avg,
                        Md.Latestw.Avg,
                    ],
                }));
            }
        }
    }, [loading, kpiSettings, selectedAdjDeposit, IncServices]);

    useEffect(() => {
        if (country !== "GB") setSelectedAdjDeposit("Total Payable by month");
    }, [country]);

    const getScopeForTitle = useCallback(
        (kpiTitle) => {
            const countryCode = getFirstFilterValue("filterCountryId");
            const country = countriesMap(countryCode);
            const duration = getFirstFilterValue("filterDuration");
            const channel1 = getFirstFilterValue("filterChannel1");
            const monthCode = getFirstFilterValue("filterMthCode");
            const kpiName = kpiTitle || measures?.selectedKpi?.name;
            const adjdeposit = countryCode === "GB" && pathname !== "/trends" ? `| Target deposit : ${selectedAdjDeposit === "Total Payable by month" ? "default" : selectedAdjDeposit} ` : "";

            return `${country} | ${duration} | ${channel1} | ${monthCode} ${adjdeposit} | ${kpiName} ${kpiName === "Leasing Factor" ? "" : countryCode === "UK" ? "| GBP" : countryCode === "GB" ? "| GBP" : "| EUR"}`;
        },
        [measures, getFirstFilterValue, selectedAdjDeposit],
    );

    const getScopeForTitleHistory = useCallback(
        (page = null) => {
            const countryCode = getFirstFilterValue("filterCountryId");
            const country = countriesMap(countryCode);
            const duration = getFirstFilterValue("filterDuration");
            const channel1 = getFirstFilterValue("filterChannel1");
            const kpiName = page ? page : measures?.selectedKpi?.name;
            const currency = ["UK", "GB"].includes(countryCode) ? "| GBP" : "| EUR";
            const adjdeposit = countryCode === "GB" && pathname !== "/trends" ? `| Target deposit : ${selectedAdjDeposit === "Total Payable by month" ? "default" : selectedAdjDeposit} ` : "";

            return `${country} | ${duration} | ${channel1} ${adjdeposit} | ${kpiName} ${currency}`;
        },
        [measures, getFirstFilterValue, selectedAdjDeposit],
    );

    const updateMeasures = useCallback((name, value) => setMeasures((prevState) => ({ ...prevState, [name]: value })), []);

    return <MeasuresContext.Provider value={{ ...measures, updateMeasures, getScopeForTitle, getScopeForTitleHistory, selectedAdjDeposit, setSelectedAdjDeposit }}>{children}</MeasuresContext.Provider>;
};

export default MeasuresContext;

export { MeasuresProvider, countriesMap, flagServiceMap, Deposit };
